import React from 'react'
import Helmet from 'react-helmet'
import parse from 'html-react-parser'

const isClient = typeof window !== 'undefined'

/**
 * Parses Yoast SEO's yoast head
 * @param {String} yoastHead - Complete yoast_head output
 * @param {String} title - Yoast SEO title
 */
export const YoastSEO = ({ title = '', yoastHead = '' }) => {
  /**
   * This function replaces the instance of the <script type="application/ld" ... />..</script>
   * programmatically.
   * https://github.com/remarkablemark/html-react-parser/issues/98
   */
  const scriptReplaceFn = {
    replace: domNode => {
      if (isClient && domNode.type === 'script') {
        const script = document.createElement('script')
        script.classList.add(domNode.attribs.class)
        script.type = domNode.attribs.type
        const scriptContent = document.createTextNode(domNode.children[0]?.data)
        script.appendChild(scriptContent)
        document.head.appendChild(script)
      }
    },
  }
  /**
   * Parse our yoast_head information
   */

  const parsedYoastHead = parse(yoastHead, scriptReplaceFn)
  const titleIndex = parsedYoastHead.findIndex(item => item?.props?.property === 'og:title' )
  const parsedTitle = parsedYoastHead.[titleIndex].props.content
  return <Helmet title={parsedTitle}>{parse(yoastHead, scriptReplaceFn)}</Helmet>
}
